import axios from "./httpClient";

export function getTasksApi(params) {
  return axios.get(`olympus/tasks`, {
    params: { ...params },
  });
}

export function redoTaskApi(taskId) {
  return axios.post(`olympus/tasks/redo`, {
    taskId,
  });
}

export function redoDocumentApi(taskId) {
  return axios.post(`olympus/tasks/documents/redo`, {
    taskId,
  });
}

export function markAsPrintedApi(taskId, applicationTokens) {
  return axios.post(`olympus/tasks/mark-as-printed`, {
    taskId,
    applicationTokens,
  });
}
