import React, { Component } from "react";
import { Menu, Accordion, Sidebar } from "semantic-ui-react";
import { Link } from "react-router-dom";
import TextIcon from "../../../TextIcon";
import { connect } from "react-redux";
import { AuthService } from "../../../../services/authService";
import { withRouter } from "react-router-dom";
import Spinner from "../../../Spinner";
import { ROLES } from "../../../../constants/rolesConstants";
import { LocalStorageService } from "../../../../services/localStorageService";
import classNames from "classnames";
import "./SideMenu.styles.scss";
import { emitLogout } from "../../../../events";
import className from "classnames";
import {
  PRODUCT_NAMES,
  filterInvalidProductProps,
} from "../../../../Router/routers/DashboardRouter/routers/Products/constants/productNames";
import apis from "../../../../apis";
import { EVENT_TYPE, LOG_TYPE } from "../../../../constants/logs";

class SideMenu extends Component {
  state = {
    activeIndex: LocalStorageService.menuIndex,
    width: window.innerWidth,
  };

  handleKeyDown = (event) => {
    if ((event.ctrlKey || event.metaKey) && ["a", "A"].includes(event.key)) {
      event.preventDefault();
    }
  };
  componentDidMount() {
    this.updateScreenWidth();
    window.addEventListener("resize", this.updateScreenWidth);
    document.addEventListener("keydown", this.handleKeyDown);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenWidth);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  updateScreenWidth = () => {
    this.setState({ width: window.innerWidth });
  };

  handleAccordionItemClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
    LocalStorageService.menuIndex = newIndex;
  };

  renderTextIcon = (iconName, value, showText = true) => {
    return <TextIcon name={iconName}>{showText ? value : null}</TextIcon>;
  };

  createEventLog = async (currentPath, destination) => {
    const { user } = this.props;
    try {
      await apis.logs.createEventLog({
        logType: LOG_TYPE.OUTSIDE_APPLICANT,
        eventType: EVENT_TYPE.NAVIGATION,
        userId: user.id,
        actions: [
          {
            propertyName: "navigation",
            oldValue: currentPath,
            newValue: destination,
          },
        ],
      });
    } catch (e) {
      console.error(e);
    }
  };

  /**
   *
   * @param {string} route
   * @param {string} icon
   * @param {string} title
   * @param {{visibleForRoles:string[]|null,routeRegexMatch:RegExp|null}} options
   */
  renderMenuItem(route, icon, title, options = {}) {
    const { user, extended } = this.props;
    const { width } = this.state;
    const { pathname } = this.props.location;
    const { visibleForRoles = null, routeRegexMatch = null } = options;
    const routeWithDash = `/${route}`;

    const isMobile = width < 930;

    if (
      visibleForRoles &&
      !AuthService.checkRolesForUser(user, visibleForRoles)
    ) {
      return null;
    }
    const isActive = routeRegexMatch
      ? routeRegexMatch.test(pathname)
      : pathname === routeWithDash;

    return (
      <Menu.Item
        as={Link}
        to={routeWithDash}
        name={route}
        active={isActive}
        key={route}
        onClick={() => this.createEventLog(pathname, routeWithDash)}
      >
        {this.renderTextIcon(icon, title, isMobile ? true : extended)}
      </Menu.Item>
    );
  }

  getMenu(isSidebar, sideMenuExtended = false) {
    const { user, setExtended, productConfig } = this.props;
    const { activeIndex } = this.state;

    const GRS_SITES = filterInvalidProductProps({
      [PRODUCT_NAMES.DS160]: "U.S. Visa",
      [PRODUCT_NAMES.ESTA]: "ESTA",
      [PRODUCT_NAMES.ETA]: "Canada ETA",
      [PRODUCT_NAMES.NZ]: "New Zeland ETA",
      [PRODUCT_NAMES.TRK]: "Trk ETA",
      [PRODUCT_NAMES.AUS]: "Aus ETA",
      [PRODUCT_NAMES.IND]: "Ind ETA",
      [PRODUCT_NAMES.VN]: "Vn ETA",
      [PRODUCT_NAMES.INV]: "Invitation Letter",
      [PRODUCT_NAMES.SCHENGEN]: "Schengen",
      [PRODUCT_NAMES.DIVERSITY]: "Diversity VISA",
      [PRODUCT_NAMES.GE]: "Global Entry",
      [PRODUCT_NAMES.SG]: "Singapore ETA",
      [PRODUCT_NAMES.DS11]: "DS11 - Passport Service",
      [PRODUCT_NAMES.DS82]: "DS82 - Passport Service",
      [PRODUCT_NAMES.DS64]: "DS64 - Passport Service",
      [PRODUCT_NAMES.SL]: "Sri Lanka ETA",
      [PRODUCT_NAMES.TSA]: "TSA Pre-Check",
      [PRODUCT_NAMES.SENTRI]: "SENTRI",
      [PRODUCT_NAMES.NEXUS]: "NEXUS",
    });

    if (user === null) {
      return <Spinner />;
    } else {
      return (
        <Accordion
          as={Menu}
          fixed="left"
          borderless
          className={classNames(
            { "main-container__sidebar": isSidebar },
            { "main-container__sidemenu": !isSidebar },
            {
              "main-container__sidemenu--shrunk":
                !sideMenuExtended && !isSidebar,
            },
          )}
          vertical
          fluid={isSidebar ? true : false}
        >
          {sideMenuExtended || isSidebar ? (
            <>
              <Menu.Item>
                <Accordion.Title
                  content={this.renderTextIcon("user", "My account", true)}
                  onClick={this.handleAccordionItemClick}
                  index={0}
                  active={activeIndex === 0}
                />
                <Accordion.Content active={activeIndex === 0}>
                  <Menu vertical fluid>
                    {this.renderMenuItem(
                      "my-account/admin/partners",
                      "user circle",
                      "Admin area",
                      {
                        routeRegexMatch: /\/my-account\/admin\/.*/,
                        visibleForRoles: [ROLES.ADMIN],
                      },
                    )}
                  </Menu>
                </Accordion.Content>
              </Menu.Item>

              <Menu.Item>
                <Accordion.Title
                  content="Visa Interview Dates"
                  onClick={this.handleAccordionItemClick}
                  index={1}
                  active={activeIndex === 1}
                />
                <Accordion.Content active={activeIndex === 1}>
                  <Menu vertical fluid>
                    {this.renderMenuItem(
                      "interview-dates",
                      "angle right",
                      "Interview dates",
                    )}
                    {this.renderMenuItem(
                      "interview-dates/configuration",
                      "angle right",
                      "Configuration",
                      {
                        visibleForRoles: [ROLES.ADMIN],
                      },
                    )}
                  </Menu>
                </Accordion.Content>
              </Menu.Item>

              {Object.values(productConfig).length ? (
                <Menu.Item>
                  <Accordion.Title
                    content="GRS"
                    onClick={this.handleAccordionItemClick}
                    index={2}
                    active={activeIndex === 2}
                  />
                  <Accordion.Content active={activeIndex === 2}>
                    <Menu vertical fluid>
                      {Object.entries(productConfig)
                        .filter(([, value]) => value)
                        .map(([key]) =>
                          this.renderMenuItem(
                            key,
                            "angle right",
                            GRS_SITES[key],
                          ),
                        )}
                    </Menu>
                  </Accordion.Content>
                </Menu.Item>
              ) : null}
            </>
          ) : (
            <>
              <Menu.Item name="my-account" onClick={() => setExtended(true)}>
                {this.renderTextIcon("user", "My account", false)}
              </Menu.Item>
              <Menu.Item name="grs" onClick={() => setExtended(true)}>
                <b>GRS</b>
              </Menu.Item>
            </>
          )}
          <div className="main-container__sidemenu__bottom">
            {this.renderMenuItem("users", "users", "Users", {
              visibleForRoles: [
                ROLES.ADMIN,
                ROLES.L1_MANAGER,
                ROLES.L2_MANAGER,
                ROLES.QA_MANAGER,
                ROLES.BT_MANAGER,
              ],
            })}
            {this.renderMenuItem("tasks", "tasks", "Tasks", {
              routeRegexMatch: /\/tasks\/[^/]+/,
              visibleForRoles: [ROLES.ADMIN, ROLES.PKO_OFFICE],
            })}

            {this.renderMenuItem(
              "support/general",
              "configure",
              "Configuration",
              { routeRegexMatch: /\/support\/[^/]+/ },
            )}
            <Menu.Item
              name="sign-out"
              onClick={() => {
                emitLogout();
              }}
            >
              {this.renderTextIcon(
                "sign-out",
                "Log out",
                isSidebar ? true : sideMenuExtended,
              )}
            </Menu.Item>
          </div>
        </Accordion>
      );
    }
  }

  render() {
    const { match, visible, setVisible, extended } = this.props;
    const { width } = this.state;

    const isMobile = width < 930;
    const isOnChat = match.path.includes("chat");

    return (
      <div className={className("main-container")}>
        {isMobile ? (
          <Sidebar.Pushable>
            <Sidebar
              as={Accordion}
              animation="overlay"
              onHide={() => setVisible(false)}
              visible={visible}
              className="main-container__sidebar"
            >
              {this.getMenu(true)}
            </Sidebar>

            <Sidebar.Pusher>
              <div
                className={classNames("main-container__content", {
                  "main-container__content--no-padding": isOnChat,
                })}
              >
                {this.props.children}
              </div>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        ) : (
          <>
            <div
              className={classNames("main-container__sidemenu", {
                "main-container__sidemenu--shrunk": !extended,
              })}
            >
              {this.getMenu(false, extended)}
            </div>
            <div
              className={classNames("main-container__content", {
                "main-container__content--no-padding": isOnChat,
              })}
            >
              {this.props.children}
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ currentUser, productConfig }) => {
  return {
    user: currentUser,
    productConfig: productConfig,
  };
};

export default connect(mapStateToProps)(
  withRouter((props) => <SideMenu {...props} />),
);
