import React, { useCallback, useEffect, useState } from "react";
import {
  Header,
  Segment,
  Table,
  Input,
  Loader,
  Select,
  Button,
  Icon,
  Confirm,
} from "semantic-ui-react";
import { useFilters, notEmptyValidator } from "../../../../../hooks";
import {
  getTasksApi,
  redoTaskApi,
  redoDocumentApi,
  markAsPrintedApi,
} from "../../../../../apis/tasksApi";
import { usePagination } from "../../../../../hooks/usePagination.hook";
import moment from "moment";

const TASK_STATUSES = [
  { key: "idle", text: "Idle", value: "idle" },
  { key: "processed", text: "Processed", value: "processed" },
  { key: "pending", text: "Pending", value: "pending" },
  { key: "printed", text: "Printed", value: "printed" },
  { key: "errored", text: "Errored", value: "errored" },
];

const columns = [
  { label: "Task ID", prop: "id", width: 3 },
  { label: "Type", prop: "taskType", width: 1 },
  { label: "Status", prop: "status", width: 1 },
  { label: "Documents", prop: "dsPdfUrl", width: 1 },
  { label: "Pictures", prop: "picturesUrl", width: 1 },
  { label: "Shipping", prop: "shippingLabelsUrl", width: 1 },
  { label: "Created At", prop: "createdAt", width: 2 },
  { label: "Redo", prop: "redo", width: 1 },
  { label: "Update Status", prop: "updateStatus", width: 1 },
];

const TasksPage = () => {
  const [expandedRows, setExpandedRows] = useState({});
  const [confirmAction, setConfirmAction] = useState(null);

  const { filters, normalizedFilters, setFormFilter } = useFilters(
    {
      taskStatus: "processed",
    },
    {
      localStorageKey: "tasks",
      filterValidators: {
        taskStatus: notEmptyValidator(),
      },
      temporaryFilters: ["taskStatus"],
    },
  );

  const {
    items: tasks,
    Paginator,
    loadItems: loadData,
    setItems: setTasks,
    isLoading,
  } = usePagination(getTasksApi, {
    initialLoad: false,
    clearOnLoad: false,
    dataProperty: "tasks",
  });

  useEffect(() => {
    loadData(1, normalizedFilters);
  }, [normalizedFilters, loadData, setTasks]);

  const handleRedoTask = async (taskId) => {
    try {
      await redoTaskApi(taskId);
      loadData(1, normalizedFilters);
    } catch (error) {
      console.error("Error redoing task:", error);
    }
  };

  const handleRedoDocuments = async (taskId) => {
    try {
      await redoDocumentApi(taskId);
      loadData(1, normalizedFilters);
    } catch (error) {
      console.error("Error redoing documents:", error);
    }
  };

  const handleMarkAsPrinted = async (taskId) => {
    try {
      const applicationTokens = tasks
        .find((task) => task.id === taskId)
        .applications.map((app) => app.form.applicationToken);
      await markAsPrintedApi(taskId, applicationTokens);
      loadData(1, normalizedFilters);
    } catch (error) {
      console.error("Error marking as printed:", error);
    }
  };

  const renderHeader = useCallback(() => {
    return (
      <Segment>
        <Header as="h1">Tasks</Header>
        <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <Select
            placeholder="Select status"
            options={TASK_STATUSES}
            value={filters.taskStatus}
            name="taskStatus"
            onChange={setFormFilter}
          />
        </div>
      </Segment>
    );
  }, [filters.taskStatus, setFormFilter]);

  const renderExpandedContent = (task) => (
    <Table.Row>
      <Table.Cell colSpan={columns.length}>
        <div style={{ display: "flex", gap: "2rem" }}>
          <div
            style={{
              flex: "0 0 25%",
              borderRight: "1px solid #ccc",
              paddingRight: "1rem",
            }}
          >
            <h4>Applications</h4>
            {task.applications?.map((app) => (
              <div
                key={app.id}
                style={{
                  marginBottom: "1rem",
                  border: "1px solid #ccc",
                  padding: "1rem",
                  borderRadius: "5px",
                }}
              >
                <div>
                  <a
                    href={`${app.form.formType}/applicant/${app.form.data._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={`${app.form.data.firstName} ${app.form.data.lastName}(${app.form.data.userEmail})`}
                    style={{
                      textDecoration: "underline",
                      color: "blue",
                      fontWeight: "bold",
                    }}
                  >
                    {app.form.data.firstName} {app.form.data.lastName} &nbsp;(
                    {app.form.data.userEmail})
                  </a>
                  <br />
                  {app.form.data.trackingNumber
                    ? `Tracking Number: ${app.form.data.trackingNumber}`
                    : ""}
                </div>
              </div>
            ))}
          </div>
          <div style={{ flex: "0 0 73%" }}>
            <h4>Errors</h4>
            {task.errors &&
              Object.keys(task.errors).map((errorKey) => (
                <div
                  key={errorKey}
                  style={{
                    marginBottom: "1rem",
                    padding: "1rem",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    backgroundColor: "#f9f9f9",
                    wordWrap: "break-word", // Added to wrap text
                    overflowWrap: "break-word", // Added for better compatibility
                  }}
                >
                  <strong>{`${
                    errorKey.charAt(0).toUpperCase() + errorKey.slice(1)
                  } Error:`}</strong>
                  <pre
                    style={{
                      margin: "0",
                      backgroundColor: "#f0f0f0",
                      padding: "0.5rem",
                      borderRadius: "3px",
                      whiteSpace: "pre-wrap", // Added to wrap text in pre
                    }}
                  >
                    {task.errors[errorKey].message}
                  </pre>
                  <pre
                    style={{
                      margin: "0",
                      backgroundColor: "#f0f0f0",
                      padding: "0.5rem",
                      borderRadius: "3px",
                      whiteSpace: "pre-wrap", // Added to wrap text in pre
                    }}
                  >
                    {task.errors[errorKey].stack}
                  </pre>
                </div>
              ))}
          </div>
        </div>
      </Table.Cell>
    </Table.Row>
  );

  const renderTable = useCallback(() => {
    if (isLoading) {
      return <Loader active />;
    }

    return (
      <>
        <Table celled>
          <Table.Header>
            <Table.Row>
              {columns.map(({ label, width }) => (
                <Table.HeaderCell key={label} width={width}>
                  {label}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tasks && tasks.length > 0 ? (
              tasks.map((task) => (
                <React.Fragment key={task.id}>
                  <Table.Row>
                    <Table.Cell
                      onClick={() =>
                        setExpandedRows((prev) => ({
                          ...prev,
                          [task.id]: !prev[task.id],
                        }))
                      }
                      style={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#f0f0f0",
                        },
                      }}
                    >
                      <Icon
                        name={
                          expandedRows[task.id]
                            ? "chevron down"
                            : "chevron right"
                        }
                        style={{ cursor: "pointer", marginRight: "1rem" }}
                      />
                      {task.id}
                    </Table.Cell>
                    <Table.Cell style={{ textTransform: "uppercase" }}>
                      {task.applications.length > 0
                        ? task.applications[0].form.formType
                        : "UNKOWN"}
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        textTransform: "uppercase",
                        backgroundColor:
                          task.status === "errored" ? "red" : "transparent",
                        color: task.status === "errored" ? "white" : "inherit",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {task.status}
                    </Table.Cell>
                    <Table.Cell>
                      {task.dsPdfUrl && (
                        <a
                          href={task.dsPdfUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Document
                        </a>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {task.picturesUrl && (
                        <a
                          href={task.picturesUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Pictures
                        </a>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {task.shippingLabelsUrl && (
                        <a
                          href={task.shippingLabelsUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Shipping Labels
                        </a>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(task.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                    </Table.Cell>
                    <Table.Cell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        <Button
                          size="tiny"
                          onClick={() =>
                            setConfirmAction({ type: "redoTask", id: task.id })
                          }
                        >
                          Redo Task
                        </Button>
                        <Button
                          size="tiny"
                          onClick={() =>
                            setConfirmAction({
                              type: "redoDocuments",
                              id: task.id,
                            })
                          }
                        >
                          Redo Documents
                        </Button>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        size="small"
                        onClick={() =>
                          setConfirmAction({
                            type: "markAsPrinted",
                            id: task.id,
                          })
                        }
                      >
                        Mark as Printed
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                  {expandedRows[task.id] && renderExpandedContent(task)}
                </React.Fragment>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={columns.length + 1}>
                  No tasks available.
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={columns.length + 1}>
                <Paginator />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>

        <Confirm
          open={!!confirmAction}
          onCancel={() => setConfirmAction(null)}
          onConfirm={() => {
            if (!confirmAction) return;

            switch (confirmAction.type) {
              case "redoTask":
                handleRedoTask(confirmAction.id);
                break;
              case "redoDocuments":
                handleRedoDocuments(confirmAction.id);
                break;
              case "markAsPrinted":
                handleMarkAsPrinted(confirmAction.id);
                break;
            }
            setConfirmAction(null);
          }}
          content={
            confirmAction?.type === "redoTask"
              ? "Are you sure you want to redo this task?"
              : confirmAction?.type === "redoDocuments"
              ? "Are you sure you want to redo the documents?"
              : "Are you sure you want to mark this as printed?"
          }
        />
      </>
    );
  }, [isLoading, tasks, Paginator, expandedRows, confirmAction]);

  return (
    <>
      {renderHeader()}
      {renderTable()}
    </>
  );
};

export default TasksPage;
