import React from "react";
import Layout from "../../../components/Layout/Layout.component";
import ConfigurationMenu from "./pages/Configuration";
import QATicketPage from "./pages/QATicket/QATicket.page";
import redirect from "../../modules/redirect";
import NestedRoutes from "../../components/NestedRoutes";
import { ROLES } from "../../../constants/rolesConstants";
import { ProductsRouter, UsersRouter, MyAccountRouter } from "./routers";
import InterviewDatesPage from "./pages/InterviewDates";
import InterviewConfigurationPage from "./pages/InterviewConfiguration";
import TasksPage from "./pages/Task";

const routes = [
  {
    path: "/support/:configType",
    component: ConfigurationMenu,
  },
  {
    path: "/tasks",
    component: TasksPage,
    authorizedRoles: [ROLES.ADMIN, ROLES.PKO_OFFICE],
  },
  {
    path: "/users",
    authorizedRoles: [
      ROLES.ADMIN,
      ROLES.L1_MANAGER,
      ROLES.L2_MANAGER,
      ROLES.QA_MANAGER,
      ROLES.BT_MANAGER,
    ],
    exact: false,
    component: UsersRouter,
  },
  {
    path: "/my-account",
    exact: false,
    component: MyAccountRouter,
  },
  {
    path: "/interview-dates",
    component: InterviewDatesPage,
  },
  {
    path: "/interview-dates/configuration",
    component: InterviewConfigurationPage,
  },
  {
    path: "/evaluation-tickets/:id",
    component: QATicketPage,
  },
  {
    path: "",
    component: redirect("/esta"),
  },
  {
    component: ProductsRouter,
    exact: false,
  },
];

const DashboardRouter = () => {
  return (
    <Layout>
      <NestedRoutes routes={routes} />
    </Layout>
  );
};

export default DashboardRouter;
